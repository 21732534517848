.sidebar {
  // display: flex;
  width: 400px;
  height: calc(100vh - 56px);
  transition: all 0.2s ease;

  &.hidden {
    transform: translateX(-100%);

    .sidebar__button {
      transform: rotateY(180deg);
    }
  }
}

.slidebar__container {
  // flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 56px);
}

.sidebar__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  height: 56px;
  background-color: #2f2f2f;

  .sidebar__header_input-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    padding: 10px;

    input {
      width: 100%;
      padding: 8px;
      color: #f0f0f0;
      font-weight: 600;
      font-size: 18px;
      background-color: #2f2f2f;
      border: none;
      transition: 100ms;
    }
  }
}

.sidebar__tabs {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  background-color: #2f2f2f;

  .sidebar__tabs_tabs {
    display: flex;
    flex-direction: row;
    height: 32px;
    background-color: #242425;

    .sidebar__tabs_tabs__button {
      display: flex;
      flex-direction: row;
      padding: 10px 16px;
      border-right: 1px solid #000;

      p {
        font-size: 12px;
      }
    }
  }

  .sidebar__tabs_content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 32px - 56px);

    .sidebar__tabs_content__block_button {
      position: relative;
    }

    .sidebar__tabs_content__block_content {
      display: flex;
      flex-direction: column;
      // height: calc(100vh - 32px - 56px - 74px);
      overflow: auto;
    }
  }

  .sidebar__tabs_content__with_button {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 32px - 56px - 74px);

    .sidebar__tabs_content__block_button {
      position: relative;
    }

    .sidebar__tabs_content__block_content {
      display: flex;
      flex-direction: column;
      // height: calc(100vh - 32px - 56px - 74px);
      overflow: auto;
    }
  }
}

.change__button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  height: 74px;
  padding: 16px;
  background-color: #242425;

  .change__button_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    width: 100%;
    padding: 14px;
    background-color: #5f9bfa;
    border-radius: 4px;

    p {
      color: #fff;
      font-size: 14px;
    }
  }

  .change__button_button_stop {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    width: 100%;
    padding: 14px;
    border: 2px solid #838383;
    border-radius: 4px;

    p {
      color: #fff;
      font-size: 14px;
    }
  }

  .change__button_button:disabled {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    width: 100%;
    padding: 14px;
    background-color: #4a4a4a;
    border-radius: 4px;

    p {
      color: #999;
      font-size: 14px;
    }
  }
}

.sidebar__result_tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 16px 20px 16px;
}

.slidebar__form {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 400px;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(0deg, #2f2f2f, #2f2f2f);
  border-right: 1px solid #1d1d1f;
}

.sidebar__title {
  color: #f0f0f0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.sidebar__buttons {
  position: absolute;
  top: 0;
  right: -57px;
  padding: 16px;
  font-size: 0;
  background: linear-gradient(0deg, #2f2f2f, #2f2f2f);
  border-right: 1px solid #1d1d1f;
  border-bottom: 1px solid #1d1d1f;
  border-radius: 0 0 4px 0;
  transition: all 0.2s ease;
}

.sidebar__button {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;

  path,
  g {
    transition: all 0.25s ease;
  }

  &.active,
  &:active {
    g {
      opacity: 1;
    }

    path {
      fill: #5f9bfa;
    }
  }

  &:hover {
    g {
      opacity: 1;
    }
  }
}

.buildings_types_add_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 8px 12px;
}

.buildings_types_add_button:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 8px 12px;
  background-color: #999;
}

.sidebar__buttons-bottom-row {
  padding-top: 15px;

  .sidebar__button {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.scrolled {
  & + .slidebar__form-bottom {
    background: #323232;
    box-shadow: 0 -4px 24px rgba(36, 36, 37, 0.56);
  }
}

.slidebar__form-bottom {
  position: relative;
  z-index: 1;
  padding: 16px;
  transition: all 0.25s ease;
}

.fields {
  margin-right: 16px;
  margin-left: 16px;
  padding-top: 16px;
}

.fields-dropdown-arrow {
  font-size: 0;
  transition: all 0.25s ease;

  &.isDisabled {
    path {
      fill: #4a4a4a;
    }
  }

  &.menuIsOpen {
    transform: rotate(180deg);
  }
}

.fields__row {
  &_checkbox {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &:not(:last-child):not(.fields__row_checkbox) {
    margin-bottom: 16px;
  }
}

.fields__title {
  margin-bottom: 8px;
  color: #999;
}

.fields__sub-title {
  margin-bottom: 16px;
  cursor: pointer;
}

.create-area-button {
  width: 32px;
  height: 32px;
  margin-left: 8px;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 3px;
  transition: all 0.25s ease;

  &.active {
    background: #242425;

    path {
      fill: #5f9bfa;
    }
  }

  path {
    transition: all 0.25s ease;
  }

  &:hover {
    background: #2b2b2b;
  }
}

.dropdown-menu-container {
  font-size: 0;
}

.dropdown-menu-project {
  .menu-list {
    right: -5px;
    left: auto;
  }

  .menu-list__arrow::after {
    right: 11px;
    left: auto;
  }
}

.menu-list__arrow {
  position: relative;

  &::after {
    position: absolute;
    top: -10px;
    left: 11px;
    z-index: -1;
    width: 12px;
    height: 12px;
    background: #242425;
    transform: rotate(45deg);
    content: '';
  }
}

.menu-list {
  position: absolute;
  top: 38px;
  left: 4px;
  z-index: 999;
  padding: 4px 0;
  background: #242425;
  border-radius: 3px;
  box-shadow: 0 2px 8px #272727;
}

.menu-list__button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
  color: #cccdcd;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  text-align: left;
  transition: all 0.25s ease;

  &:hover {
    color: #f0f0f0;
    background: rgba(153, 153, 153, 0.16);
  }
}

.fields__bottom {
  padding-top: 16px;
  padding-bottom: 12px;
  border-top: 1px solid rgba(153, 153, 153, 0.16);
}

.fields__cell-container {
  display: flex;
  align-items: center;
}

.fields__cell {
  width: calc(50% - 8px);

  .fields__input {
    width: 100%;
  }

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.pre-edit-placeholder {
  position: absolute;
  top: 0;
  left: 16px;
  z-index: 0;
  color: transparent;
  background-color: rgba($color: #999, $alpha: 0.6);
  caret-color: transparent;
}

.connections-draft-text.text {
  color: #999;
  line-height: 20px;
}

.connections-row__title {
  margin-top: 16px;
  margin-bottom: 6px;
  color: #999;
}

.connections-row__value {
  color: #f0f0f0;
}

.connections-row {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba($color: #f0f0f0, $alpha: 0.16);
}

.control-button__area {
  margin-left: 8px;
  border-radius: 3px;

  &:hover {
    background: linear-gradient(0deg, rgba(240, 240, 240, 0.04), rgba(240, 240, 240, 0.04)), #2b2b2b;
  }

  &.active {
    background: #f0f0f0;

    path {
      fill: #2f2f2f;
    }
  }
}
