.map_buildings-connect {
  position: absolute;
  right: 10px;
  bottom: 10px;
  left: 10px;
  z-index: 21;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #323232;
  border-radius: 5px;
}

.map_buildings-connect__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 5px 10px;

  p {
    color: #f0f0f0;
  }
}

.map_buildings-connect__row-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.map_table {
  display: grid;
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  width: 100%;
  background-color: #323232;
  border-radius: 5px;
}

.map_table__header {
  display: grid;
  grid-template-columns: 40px 100px 100px 100px 1fr 1fr 150px 20px;
  padding: 7px 15px;
}

.map_table__header-link {
  display: 'flex';
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  width: 100%;
  padding: 0 5px;
  background-color: #323232;

  p {
    color: #999;
    font-size: 14px;
  }
}

.map_table__rows {
  display: grid;
  grid-auto-columns: auto;
  max-height: 300px;
  overflow: auto;
}

.map_table__rows__row {
  display: grid;
  padding: 7px 5px 7px 15px;
}

.map_table__rows__row:last-child {
  display: grid;
  grid-template-columns: 40px 100px 100px 100px auto auto 150px 50px;
  padding: 7px 5px 7px 15px;
  border-radius: 5px;
}

.map_table__rows__confirm_button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.map_table__rows__confirm_button:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(74, 74, 74, 0.6);
  border-radius: 4px;
}

.map_table__rows__cancel_button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.map_table__rows__cancel_button:hover {
  background-color: rgba(74, 74, 74, 0.6);
  border-radius: 4px;
}

.map_table__rows__row-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  width: 100%;
  padding: 0 5px;

  input {
    width: 100%;
    padding: 8px;
    color: #cccdcd;
    font-size: 12px;
    background-color: #242425;
    border: 1px solid #4a4a4a;
    border-radius: 5px;
  }

  input[type='text']::-webkit-input-placeholder {
    color: #cccdcd;
  }
}

.map_table__row__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  background-color: #242425;
  border: 1px solid #4a4a4a;
  border-radius: 5px;

  p {
    width: 65px;
    overflow: clip;
    color: #cccdcd;
    font-size: 12px;
    white-space: nowrap;
    text-align: start;
    text-overflow: ellipsis;
  }

  div {
    // position: absolute;
    right: 10px;
  }
}

.map_table__row__confirm_delete {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: center;
}

.map_table__dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .map_table__dropdown-list {
    position: absolute;
    z-index: 20;
    // bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    max-height: 400px;
    overflow: auto;
    // width: 300px;
    // justify-content: center;
    background: #242425;
    border: 1px solid #4a4a4a;
    border-radius: 5px;

    .map_table__dropdown-list__row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      justify-content: center;
      padding: 5px 5px;

      p {
        width: 80px;
        overflow: clip;
        color: #cccdcd;
        text-align: start;
        text-overflow: ellipsis;
      }
    }
  }
}

.map_table__search {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .map_table__search-list {
    position: absolute;
    z-index: 20;
    // bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 300px;
    max-height: 400px;
    overflow: auto;
    // width: 300px;
    // justify-content: center;
    background: #242425;
    border: 1px solid #4a4a4a;
    border-radius: 5px;

    .map_table__search-list__row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 5px 5px;

      p {
        min-width: 32px;
        color: #cccdcd;
        text-align: start;
      }
    }
  }
}
