.control-buttons {
  position: fixed;
  top: 80px;
  right: 24px;
  z-index: 9999;
}

.control-button {
  padding: 4px 8px;
  color: #cccdcd;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  background: #323232;
  transition: all 0.25s ease;

  &_external-points {
    margin-right: 8px;
  }

  &:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &.grid-areas-handler {
    padding: 8px;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;

    &:first-child {
      margin-right: 0;
    }
  }

  path {
    transition: all 0.25s ease;
  }

  &:hover {
    color: #f0f0f0;

    path {
      fill: #f0f0f0;
    }
  }

  &:hover:active,
  &.active {
    color: #2f2f2f;
    background-color: #f0f0f0;
    box-shadow: 0 2px rgba(36, 36, 37, 0.24);

    &.thermal-icon {
      path {
        fill: #f0f0f0;
        stroke: #2f2f2f;
      }
    }

    rect,
    circle {
      stroke: #2f2f2f;
    }

    path {
      fill: #2f2f2f;
    }
  }
}

.control-button__icon {
  margin-right: 4px;
}

.control-button__icon,
.control-button__text {
  display: inline-block;
  vertical-align: middle;
}

.grid-areas-container {
  display: inline-block;
  margin-right: 8px;
  box-shadow: 0 2px 12px rgba(36, 36, 37, 0.48);

  .grid-areas-handler {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
