.scale-buttons {
  position: fixed;
  top: 50%;
  right: 24px;
  z-index: 20;
  font-size: 0;
  transform: translateY(-50%);
}

.scale-buttons__zoom-button,
.scale-buttons__button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scale-buttons__zoom-button {
  position: relative;
  width: 100%;
  height: 50%;
  font-size: 0;
  transition: all 0.25s ease;

  path {
    transition: all 0.25s ease;
  }

  &:hover {
    background: rgba(240, 240, 240, 0.08);

    path {
      stroke: #f0f0f0;
    }
  }
}

.button-container {
  margin-bottom: 15px;
  background: #323232;
  border-radius: 4px;
}

.scale-buttons__button {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 8px #272727;

  path {
    transition: all 0.25s ease;
  }

  &:hover {
    background: rgba(240, 240, 240, 0.08);

    path {
      fill: #f0f0f0;
    }
  }
}

.scale-buttons__zoom {
  width: 40px;
  height: 80px;
  margin-bottom: 16px;
  font-size: 0;
  border-radius: 4px;
  box-shadow: 0 2px 8px #272727;
}
