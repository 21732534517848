.hidden {
  & + .map-container {
    width: 100%;
  }
}

.map-container {
  display: flex;
  flex: 1;
  width: 100%;
  height: calc(100vh - 56px);

  .mapboxgl-canvas {
    cursor: default;
  }
}

.main-popup {
  &.mapboxgl-popup-anchor-bottom {
    .mapboxgl-popup-tip {
      border-top-color: #242425;
    }
  }

  .mapboxgl-popup-tip {
    border: 8px solid transparent;
  }

  .mapboxgl-popup-content {
    display: flex;
    padding: 8px 8px 8px 12px;
    background: #242425;
    border-radius: 3px;
  }

  .main-popup__close {
    height: 16px;
    margin-left: 8px;
    font-size: 0;
  }
}

.marker-icon {
  background-color: #fff;
  border-radius: 50%;
  transition: width, height 0.1s ease;

  &.second {
    background-color: #80a9ef;
  }
}

.area-info {
  position: absolute;
  z-index: 99999;
  padding: 8px 12px;
  color: #cccdcd;
  font-size: 14px;
  line-height: 16px;
  background: rgba(36, 36, 37, 0.6);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(39, 39, 39, 0.5);
  transform: translate(12px, -6px);

  .dem-text {
    position: relative;
    display: inline-block;
    padding-right: 5px;
    color: inherit;

    &::after {
      position: absolute;
      top: -4px;
      right: -1px;
      font-size: 10px;
      content: '2';
    }
  }

  .dem-text-km {
    margin-right: 5px;
  }
}

.error-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-bottom: 2px;
  vertical-align: middle;
}

.facility-draft {
  transition: all 0.25s ease;
}

.facility-popup__text {
  display: inline-block;
  color: #999;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.facility-popup__value {
  color: #f0f0f0;
}

.main-popup__close {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  font-size: 0;

  g {
    transition: all 0.25s ease;
  }

  &:hover {
    g {
      opacity: 1;
    }
  }
}
