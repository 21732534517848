.layers-toolbar {
  position: absolute;
  top: -100px;
  // width: 50px;
  // height: 50px;
  right: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  width: min-content;
  height: min-content;
  padding: 10px 23px;
  background-color: #323232;
  border-radius: 4px;
  box-shadow: 0 2px 8px #272727;
}

.layers-toolbar__arrow {
  position: absolute;
  top: 160px;
  right: 45px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #323232;
}

.layers-toolbar__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  margin-top: 10px;
}

.layers-toolbar__title-text {
  color: rgba(204, 205, 205, 0.64);
  font-size: 12px;
}

.button-container {
  margin-bottom: 15px;
  background: #323232;
  border-radius: 4px;
}

.layers-toolbar__layer-button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
}

.layers-toolbar__layer-button-text-selected {
  margin-left: 10px;
  padding: 10px 0;
  color: #fff;
  color: #cccdcd;
  font-size: 14px;
  white-space: nowrap;
}

.layers-toolbar__layer-button-text-unselected {
  margin-left: 10px;
  padding: 10px 0;
  color: #fff;
  color: rgba(204, 205, 205, 0.4);
  font-size: 14px;
  white-space: nowrap;
}

.layers-toolbar__layer-button-icon-selected {
  display: flex;
  align-items: center;
  justify-content: center;
}

.layers-toolbar__layer-button-icon-selected-null {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
}

.layers-toolbar__layer-button-icon-unselected {
  display: flex;
  align-items: center;
  justify-content: center;

  & path {
    fill: rgba(204, 205, 205, 0.4);
  }
}

.layers-toolbar__icon-button {
  position: absolute;
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #323232;
  border-radius: 4px;
  box-shadow: 0 2px 8px #272727;

  &:hover {
    background: rgba(50, 50, 50, 0.9);

    path {
      fill: #f0f0f0;
    }
  }
}
