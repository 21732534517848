.task-list__favorite,
.main-logo,
.main-title,
.task-list__area,
.task-list__count,
.task-list__plus,
.task-list-right-icon,
.task-list__create-new-task-text,
.task-list__title {
  display: inline-block;
  vertical-align: middle;
}

.task-list {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  background-color: #2f2f2f;
}

.filter_solving_button__pressed {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 4px;

  p {
    color: #323232;
  }
}

.filter_solving_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: #272626;
  border-radius: 4px;

  p {
    color: #999;
  }
}

.filter_solving_button:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: rgb(153, 153, 153, 0.16);
  border-radius: 4px;

  p {
    color: #999;
  }
}

.main-title {
  color: #f0f0f0;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
}

.main-logo {
  margin-right: 16px;
}

.task-list-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding: 24px;
  border: 1px solid rgba($color: #999, $alpha: 0.4);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.task-list__status {
  display: inline-block;
  margin-bottom: 16px;
  padding: 4px 8px;
  color: #ffd4a1;
  font-size: 12px;
  line-height: 16px;
  background: rgba(255, 212, 161, 0.04);
  border-color: #ffd4a1;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;

  &.SOLVED {
    color: #a9ffc1;
    background: rgba(169, 255, 193, 0.04);
    border-color: #a9ffc1;
  }

  &.FAILED {
    color: #ff6262;
    background: rgba(241, 80, 80, 0.04);
    border-color: #ff6262;
  }

  &.DRAFT {
    color: #cccdcd;
    background: rgba(240, 240, 240, 0.04);
    border-color: #cccdcd;
  }
}

.task-list__title {
  margin-left: 24px;
  color: #f0f0f0;
  font-weight: 600;
  font-size: 16px;
}

.task-list__favorite {
  position: absolute;
  top: 69px;
  left: 24px;
  z-index: 9;
  font-size: 0;

  &.favorite {
    path {
      fill: #f0f0f0;
      stroke: #f0f0f0;
    }
  }
}

.task-list__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  padding-top: 56px;
  padding-bottom: 80px;
}

.task-list__bottom-text {
  margin-top: 8px;
  color: #999;
  font-size: 14px;
  line-height: 16px;
}

.task-list__count {
  margin-right: 24px;
}

.task-list-right-icon {
  margin-right: 8px;
}

.task-list__top-row {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  margin-bottom: 48px;
  background-color: #272727;
}

.create_task_button {
  position: absolute;
  right: 0;
  bottom: -26px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background-color: #5f9bfa;
  border-radius: 26px;
}

.create_task_button:hover {
  position: absolute;
  right: 0;
  bottom: -26px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background-color: rgb(95, 155, 250, 0.8);
  border-radius: 26px;
}

.task-list__remove {
  position: absolute;
  top: 50%;
  right: -32px;
  z-index: 9;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.25s ease;

  path {
    transition: all 0.25s ease;
  }
}

.task-list-row-container {
  &:hover {
    .task-list-row {
      background: linear-gradient(0deg, rgba(240, 240, 240, 0.04), rgba(240, 240, 240, 0.04));
      border: 1px solid rgba(153, 153, 153, 0.24);
    }

    .task-list__remove {
      opacity: 1;

      &:hover {
        path {
          fill: #c4c4c4;
        }
      }
    }
  }
}

.task-list-div-for-hover {
  position: absolute;
  top: 50%;
  right: -16px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
}

.task-list__plus {
  margin-right: 4px;
}

.task-list__create-new-task {
  color: #5f9bfa;
  font-size: 0;
  transition: all 0.25s ease;

  .task-list__plus {
    rect {
      transition: all 0.25s ease;
    }
  }

  &:hover {
    color: #85b3fb;

    .task-list__plus {
      rect {
        fill: #85b3fb;
        stroke: #85b3fb;
      }
    }
  }
}

.task-list__create-new-task-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.task-list__button {
  margin-right: 16px;
  color: rgba($color: #999, $alpha: 0.6);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.25s ease;

  &:hover {
    color: #999;
  }

  &.active {
    color: #f0f0f0;
  }
}

.task-list-handlers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task-list__bottom-row {
  text-align: center;
}

.task-list__show-more-button {
  margin-top: 48px;
  padding: 10px 16px;
  color: #f0f0f0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: #5f9bfa;
  border-radius: 4px;
}

.task-list__icon {
  font-size: 0;
}

.task-list__icon-text {
  color: #999;
  font-size: 14px;
  line-height: 16px;
}

.task-list__area-text {
  position: relative;

  &::after {
    position: absolute;
    top: -6px;
    right: -7px;
    font-size: 12px;
    content: '2';
  }
}

.task-list__remove-button-container {
  margin-right: 8px;
}

.primary-button.task-list__remove-button {
  margin-right: 8px;
  padding: 8px 12px;
  line-height: 16px;
}

.task-list-modal__button {
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
}

.task-list-modal__container {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999;
  max-width: 386px;
  background: #2f2f2f;
  border-radius: 3px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.16);
  transform: translate(-50%, -50%);
}

.task-list-modal__text {
  color: #f0f0f0;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.task-list-modal__project-name {
  font-weight: 600;
}

.task-list-modal__cancel-button {
  position: absolute;
  top: 12px;
  right: 12px;

  g {
    transition: all 0.25s ease;
  }

  &:hover {
    g {
      opacity: 1;
    }
  }
}
