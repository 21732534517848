@import './reset.scss';

* {
  box-sizing: border-box;
}

body {
  color: #cccdcd;
  font-family: 'Source Sans Pro', sans-serif;
}

// input,
// button,
// a {
//   font: inherit;
//   cursor: pointer;
// }

// input::selection {
//   color: #cccdcd;
//   background-color: rgba($color: #999, $alpha: 0.6);
// }

button:focus {
  outline: none;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font: inherit;
}

.heading-2,
.heading-1 {
  font-weight: 600;
}

.heading-1 {
  font-size: 16px;
  line-height: 24px;
}

.heading-2 {
  font-size: 14px;
  line-height: 16px;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.text-bold {
  font-weight: 600;
}

.align-middle {
  vertical-align: middle;
}

.d-inline {
  display: inline-block;
}

.relative {
  position: relative;
}

.no-wrap {
  white-space: nowrap;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.secondary-button {
  width: 100%;
  padding: 8px 12px;
  color: #323232;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  background: #f0f0f0;
  border-radius: 3px;
}

.primary-button {
  width: 100%;
  padding: 10px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: #5f9bfa;
  border-radius: 3px;
  transition: all 0.25s ease;

  &:disabled {
    color: #999;
    background: linear-gradient(0deg, #4a4a4a, #4a4a4a);
  }

  &:not([disabled]) {
    &:hover {
      background: rgba($color: #5f9bfa, $alpha: 0.8);
    }

    &:active {
      background: rgba($color: #5f9bfa, $alpha: 0.7);
    }
  }
}

.custom-scroll {
  position: relative;

  .rcs-custom-scroll {
    .rcs-inner-container {
      &::after {
        display: none;
      }
    }

    .rcs-outer-container .rcs-positioning {
      position: static;
    }

    .rcs-custom-scroll-handle {
      width: calc(100% - 2px);
      padding-bottom: 6px;
      transition: opacity 0.1s ease;
    }

    .rcs-inner-handle {
      height: 100%;
      margin-top: 4px;
      background-color: rgba($color: #999, $alpha: 0.24);
      border-radius: 100px;
      cursor: pointer;
      transition: opacity, background-color 0.2s ease;

      &:hover {
        opacity: 1;
      }
    }

    .rcs-custom-scrollbar {
      right: 0;
      z-index: 99;
      width: 10px;
      padding: 1px;
      background: #242425;

      &:hover {
        .rcs-inner-handle {
          background-color: #999;
        }
      }
    }
  }
}

.error-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.32);
}

.error-modal__container {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999999;
  display: flex;
  align-items: center;
  background: #242425;
  border-radius: 3px;
  box-shadow: 0 2px 8px #272727;
  transform: translate(-50%, -50%);
}

.error-modal__sub-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 32px;
}

.error-modal__close {
  position: absolute;
  top: 10px;
  right: 10px;

  path,
  g {
    transition: all 0.25s ease;
  }

  &:hover {
    path,
    g {
      opacity: 1;
    }
  }
}

.error-modal__text-container {
  margin-left: 16px;
}

.error-modal__copy {
  position: relative;
  color: #5f9bfa;

  &::after {
    position: absolute;
    right: 0;
    bottom: 1px;
    left: 0;
    border-bottom: 1px solid #5f9bfa;
    content: '';
  }
}

.error-modal__text-area {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  outline: none;
  cursor: default;
  opacity: 0;
}

.error-modal__button {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  .primary-button {
    width: auto;
    padding: 8px 12px;
  }
}

.error-modal__title-text {
  display: flex;
  align-items: center;
}

.__react_component_tooltip.custom-tooltip {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 16px;
  border: none;

  &.show {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-left: 0.5px solid #242425;
}

::-webkit-scrollbar-track {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 4px;
  opacity: 0.24;
}

.moreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.moreButton:hover {
  padding: 3px;
  background-color: rgb(29, 29, 31);
  border-radius: 4px;
}
